var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "current" },
    [
      _c("el-autocomplete", {
        attrs: {
          "value-key": _vm.valueKey,
          "fetch-suggestions": _vm.querySearchAsync,
          placeholder: _vm.placeholder,
          "trigger-on-focus": false,
        },
        on: { select: _vm.handleSelect },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }