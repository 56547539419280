<template>
    <div>
        <el-dialog
            title="查看配置"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="beforeClose"
            width="500"
            custom-class='dialog'
            >
            <el-form label-position="right"  class="demo-form-inline" ref="form">
                <el-form-item label="商户名称:" prop="operationId">
                    {{detailItemList.operationName}}
                </el-form-item>
                <el-form-item label="免费类型:" class="freeType">
                    <div v-for="(item, index) in freeTypeList" :key="index" style="display: inline-block;">
                        {{ item.freeRuleType}}&nbsp;&nbsp;
                    </div>
                </el-form-item>
            </el-form>
            <div class="footer">
                <el-button @click="beforeClose">返回</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       return {
           dialogVisiblePic: false,
           formInline: {
               operationId: "",
           },
           freeTypeList: []
       }
   },
   props: ['dialogVisible', 'detailItemList'],
   methods: {
      beforeClose() {
          this.$emit("dialogCheckClose")
      }
   },
   watch: {
       detailItemList() {
           this.formInline = this.detailItemList;
           this.freeTypeList = this.detailItemList.freeRuleVOS;
       }
   }
}
</script>
<style  lang="stylus" scoped>
    .freeType
        word-wrap: break-word;
    .pdaC
       color: blue;
       cursor: pointer;
    .footer
        text-align: center;
    .mask 
        background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        overflow: auto;
        .imgPic
           position: absolute
           width: 350px;
           height: 700px;
           left: 50%;
           margin-left: -175px;
           top: 50%;
           margin-top: -350px;
</style>

