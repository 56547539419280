<template>
  <div>
    <el-dialog
      :title="isEdit ? '编辑配置' : '新增配置'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="beforeClose"
      width="500"
      custom-class="dialog"
    >
      <el-form
        :inline="true"
        label-position="right"
        :model="formInline"
        class="demo-form-inline"
        ref="form"
      >
        <el-form-item :label="$t('searchModule.Merchant_Name')">
          <el-autocomplete
            :disabled="isEdit"
            v-model="formInline.operationName"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            value-key="operationName"
            :trigger-on-focus="false"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="免费类型">
              <el-input v-model="formInline.freeType" :maxlength="8"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button @click="addPage" :disabled="freeTypeList.length >= 5"
              >{{ $t('button.addto') }}</el-button
            >
          </el-col>
          <el-col :span="8">
            <div
              class="freeTypeC"
              v-for="(item, index) in freeTypeList"
              :key="index"
            >
              {{ item.freeRuleType || item
              }}<span class="deleteC" @click="getDelete(index, item)"
                >删除</span
              >
            </div>
          </el-col>
        </el-row>
        <el-form-item>
          <p>
            配置后在<span @click="dialogVisiblePic = true" class="pdaC"
              >PDA上展示</span
            >，即车辆入场后收费员根据实际情况在PDA上选择【已配置的免费类型】入场。
          </p>
        </el-form-item>
      </el-form>
      <div
        class="mask"
        v-show="dialogVisiblePic"
        @click="dialogVisiblePic = false"
      >
        <img src="./pdaPic.png" alt="" class="imgPic" />
      </div>
      <div class="footer">
        <el-button @click="beforeClose">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      picUrl: require("./pdaPic.png"),
      likeOperationName: "",
      dialogVisiblePic: false,
      formInline: {
        operationId: "",
        operationName: "",
        freeType: "",
      },
      tenantList: [],
      freeTypeList: [],
      operationName: ""
    };
  },
  props: {
    dialogVisible: {
      default: function () {
        return false;
      },
    },
    isEdit: {
      default: function () {
        return false;
      },
    },
    detailItemList: {
      default: function () {
        return {};
      },
    }
  },
  methods: {
    beforeClose() {
      this.formInline.freeType = "";
      this.formInline.operationName = "";
      this.formInline.operationId = "";
      this.freeTypeList = [];
      this.$emit("searchData");
      this.$emit("dialogCheckClose");
    },
    handleSelect(item) {
      this.formInline.operationId = item.operationId;
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/freeRule/getFreeRuleOperation", {
          data: {
            likeOperationName: queryString,
          },
        })
        .then((res) => {
          res.value.forEach((v) => {
            v.value = v.likeOperationName;
          });
          cb(res.value);
        });
    },
    picClick() {},
    getDelete(index, item) {
      if (item.id) {
        this.$confirm("删除后该规则将不再显示在PDA上，是否确认?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios
              .get("/acb/2.0/freeRule/deleteById", {
                data: {
                  id: item.id,
                },
              })
              .then((res) => {
                if (res.state == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功",
                  });
                  this.freeTypeList.splice(index, 1);
                } else {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else {
        this.freeTypeList.splice(index, 1);
      }
    },
    addPage() {
      if (this.formInline.freeType == "") {
        this.$message({
          message: "免费类型不能为空",
          type: "warning",
        });
        return;
      }
      // let obj = {}
      // obj.freeRuleType = this.formInline.freeType
      // this.freeTypeList.push(obj)
      let obj = {};
      obj.freeRuleType = this.formInline.freeType;
      this.freeTypeList.push(this.formInline.freeType);
      this.formInline.freeType = "";
    },
    submit() {
          let method = "post";
          let url = "/acb/2.0/freeRule/save";
          if (this.freeTypeList.length == 0) {
            this.$message({
              message: "免费类型不能为空",
              type: "warning",
            });
            return;
          }
          if (!this.formInline.operationId) {
            this.$message({
              message: "商户名称不能为空",
              type: "warning",
            });
            return;
          }
          let freeTypeList = this.freeTypeList.filter((v) => !v.id);
          this.$axios[method](url, {
            data: {
              operationId: this.formInline.operationId,
              freeRuleTypes: freeTypeList.join(","),
            },
          }).then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.beforeClose();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
    },
  },
  watch: {
    detailItemList() {
      if (this.isEdit) {
        this.formInline.operationName = this.detailItemList.operationName;
        this.formInline.operationId = this.detailItemList.operationId;
        this.freeTypeList = this.detailItemList.freeRuleVOS;
      }
    }
  },
  mounted() {
    console.log(this.detailItemList, 'detailItemList')
  },
};
</script>
<style  lang="stylus" scoped>
.formWidth {
  width: 221.5px;
}



.freeTypeC {
  height: 30px;
  line-height: 30px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 10px;

  .deleteC {
    margin-left: 30px;
    color: blue;
    float: right;
  }
}

.pdaC {
  color: blue;
  cursor: pointer;
}

.footer {
  text-align: center;
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;

  .imgPic {
    position: absolute;
    width: 350px;
    height: 700px;
    left: 50%;
    margin-left: -175px;
    top: 50%;
    margin-top: -350px;
  }
}
</style>

