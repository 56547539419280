<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">
            {{ $t("searchModule.Query_Table") }}
          </div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader
                  @change="handleAreaChange"
                  ref="cascader"
                ></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  :dataSource="1"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item
                :label="$t('searchModule.plate_number')"
                prop="plateNumber"
                ref="plateNumber"
              >
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  :maxlength="8"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="请输入泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <!--
          <el-form-item :label="$t('searchModule.Toll_collector')">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formInline.operatorName"
            ></el-input>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
                <el-select
                  v-model.trim="formInline.entryOrExit"
                  filterable
                  size="8"
                >
                  <el-option label="全部" value=""> </el-option>
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in ExitTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('searchModule.time_slot')"
                class="time_item"
              >
                <el-date-picker
                  v-model="searchData"
                  type="daterange"
                  align="left"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.button.query"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button type="info" icon="el-icon-upload2" @click="exportFile">
                导出
              </el-button>
            </div>
          </div>

          <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm()"
            >{{ $t('button.reset') }}</el-button
          > -->

          <!-- <el-button type="primary" icon="el-icon-upload2" @click="exportFile()" v-if='$route.meta.authority.button.query'>{{ $t('button.export') }}</el-button>  -->
          <!-- <p style="color: #909399">说明：导出数据最多一次导出10,000条，如果筛选条件范围较大，则只能导出前10,000条。</p> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'parkRecordId'"
                v-clipboard:copy="scope.row.parkRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <span>{{ scope.row.parkRecordId }}</span>
              </span>
              <span v-else-if="item.prop == 'parkTime'">
                {{ scope.row.parkTime }}
                <br />
                <b v-if="scope.row.isPreRecord == 1" class="orderNumberStyle">{{
                  $t("list.in_advance")
                }}</b>
                <!-- <b class="orderNumberStyle">新 白</b>
                 <b class="orderNumberStyle">免</b>
                 <b class="orderNumberStyle">残</b> -->
              </span>
              <span v-else>
                <span>
                  {{
                    item.formatter
                      ? item.formatter(scope.row)
                      : scope.row[item.prop]
                  }}
                </span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import i18n from "@/i18n";
export default {
  name: "parkRecordTotal",
  data() {
    const start = new Date(new Date().getTime());
    const end = new Date(new Date().getTime());
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    return {
      isPreRecord: false, // 详情弹窗是否显示 预付费记录
      flag: false,
      plateNumber: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      searchData: [
        dateFormat(start, "yyyy-MM-dd"),
        dateFormat(end, "yyyy-MM-dd"),
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      ExitTypeList: [],
      roleList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: this.$t("list.index"),
          width: "70",
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_record_ID"),
          width: "180",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "100",
        },
        {
          prop: "parkType",
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
          formatter: (row, column) => {
            if (row.parkType == 1) {
              return i18n.t("list.Parallel_roadside");
            } else if (row.parkType == 2) {
              return i18n.t("list.Roadside_vertical_parking_lot");
            } else if (row.parkType == 3) {
              return i18n.t("list.Closed_parking_lot");
            } else if (row.parkType == 4) {
              return i18n.t("list.Semi_enclosed_parking_lot");
            } else {
              return "";
            }
          },
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return i18n.t("list.Unlicensed_car");
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_No"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "freeRuleType",
          label: this.$t("list.Free_type"),
          width: "",
        },
        {
          prop: "entryOperatorName",
          label: this.$t("list.Entry_operator"),
          width: "100",
        },
        {
          prop: "exitOperatorName",
          label: this.$t("list.On_site_operator"),
          width: "100",
        },
      ],
      tableData: [],
      parkDetail: [],
      parkRecordId: 0,
      page: 1,
      size: 15,
      areaIds: "",
      formInline: {
        dataSource: 1, //	数据来源1:路内，2:路外
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        plateNumber: "",
        entryOrExit: "",
        // startDate: "",
        // endDate: "",
        berthCode: "",
      },
    };
  },
  computed: {},
  methods: {
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.$refs.cascader.clear();
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.operationId = "";
      this.formInline.plateNumber = "";
      this.formInline.entryOrExit = "";
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
      this.formInline.berthCode = "";
      const start = new Date(new Date().getTime());
      const end = new Date(new Date().getTime());
      this.searchData = [
        dateFormat(start, "yyyy-MM-dd"),
        dateFormat(end, "yyyy-MM-dd"),
      ];
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    exportFile() {
      // /attend/attendance/export
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      let opt = {
        berthCode: this.formInline.berthCode,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        areaId: areaId,
        parkId: this.formInline.parkId,
        operationId: this.formInline.operationId,
        entryOrExit: this.formInline.entryOrExit,
        startDate: this.searchData[0],
        endDate: this.searchData[1],
        plateNumber: this.formInline.plateNumber,
        carId: this.formInline.carId,
        dataSource: 1,
      };
      exportExcelNew("/acb/2.0/freeRuleRecord/export", opt);
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },

    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            dataSource: 1,
            areaIds:
              this.formInline.streetId != ""
                ? this.formInline.streetId
                : this.formInline.areaId,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
      });
    },
    showLog() {
      if (this.searchData) {
        var time = new Date(this.searchData[1]) - new Date(this.searchData[0]);
        time = time / (24 * 60 * 60 * 1000);
        if (time > 30) {
          this.$alert("搜索时间范围不能超过31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("时间段不能为空", this.$t("pop_up.Tips"), {
          confirmButtonText: this.$t("pop_up.Determine"),
        });
        return false;
      }
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordListFun() {
      let flag = this.showLog();
      if (flag) {
        if (!this.formInline.carId) {
          this.formInline.plateNumber = "";
        }
        let areaId = "";
        if (this.$refs.cascader) {
          areaId = this.$refs.cascader.getStreetId()
            ? this.$refs.cascader.getStreetId()
            : this.$refs.cascader.getAreaId();
        }
        let opt = {
          method: "get",
          url: "/acb/2.0/freeRuleRecord/query",
          data: {
            berthCode: this.formInline.berthCode,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            areaId: areaId,
            parkId: this.formInline.parkId,
            operationId: this.formInline.operationId,
            entryOrExit: this.formInline.entryOrExit,
            startDate: this.searchData[0],
            endDate: this.searchData[1],
            plateNumber: this.formInline.plateNumber,
            carId: this.formInline.carId,
            dataSource: 1,
          },
          success: (res) => {
            this.loading = false;
            if (res.state === 0) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            }
          },
          fail: () => {
            this.loading = false;
          },
        };
        this.$request(opt);
      }
    },
    // 搜索
    searParkRecordList() {
      // 9223372036854775807
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          this.flag = false;
          this.$refs.parkInput.setShowVal(this.formInline.parkName);
          this.searParkRecordListFun();
        }
      });
    },
  },
  components: {
    myComponent,
  },
  created() {
    this.getArea();
    this.getRoleList();
    this.getExitType();
    this.getParkSpinner();
  },
  mounted() {
    this.searParkRecordListFun();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_item {
  .el-date-editor.el-range-editor {
    min-width: 280px;

    .el-range-input {
      min-width: 90px;
    }

    .el-range-separator {
      width: 30px;
    }
  }
}

.el-icon-date-custom {
  position: relative;
  left: -26px;
}

.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.orderNumberStyle {
  color: #0f6eff;
}
</style>
