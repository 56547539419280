<template>
  <div class="propertyType">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item
                :label="$t('searchModule.Merchant_Name')"
                prop="operationId"
              >
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t("button.search") }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.add"
          ></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.add">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="add"
                :loading="loading"
                v-if="$route.meta.authority.button.add"
                >新增</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
          <!-- <el-form-item> -->

          <!-- <el-button type="primary" icon="el-icon-delete" @click="clearFrom()"  :loading="loading">{{ $t('button.reset') }}</el-button> -->

          <!-- </el-form-item> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70px"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            v-if="
              $route.meta.authority.button.view ||
              $route.meta.authority.button.edit
            "
            align="center"
            width=""
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-show="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <add-dialog
        :dialogVisible="dialogVisible"
        :detailItemList="detailItemList"
        :isEdit="isEdit"
        @detailItem="detailItem"
        @searchData="searchData"
        @dialogCheckClose="dialogCheckClose"
      >
      </add-dialog>
      <detail-dialog
        :dialogVisible="dialogDetailVisible"
        @dialogCheckClose="dialogCheckClose"
        :detailItemList="detailItemList"
      >
      </detail-dialog>
    </div>
  </div>
</template>
<script>
import autoComplete from "@/mycomponents/myautocomplete";
import addDialog from "./addDialog";
import detailDialog from "./detailDialog";
export default {
  name: "propertyType",
  components: {
    addDialog,
    detailDialog,
  },
  data() {
    return {
      tenantList: [], // 商户数据
      assetsTypeList: [],
      dialogVisible: false,
      dialogDetailVisible: false,
      page: 0,
      pageSize: 10,
      total: 0,
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "freeRuleType",
          label: this.$t("list.Free_type"),
          width: "",
        },
      ],
      loading: false,
      isEdit: false,
      tableData: [],
      detailItemList: {},
      formInline: {
        assetsTypeName: "",
        assetsTypeId: "",
        operationId: "",
      },
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    add() {
      this.dialogVisible = true;
    },
    dialogCheckClose() {
      this.dialogVisible = false;
      this.dialogDetailVisible = false;
      this.isEdit = false;
    },
    clearFrom() {
      this.formInline.assetsTypeName = "";
      this.formInline.operationId = "";
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.detailItem(data);
        this.dialogDetailVisible = true;
      } else if (cmd === "b") {
        this.detailItem(data);
        this.isEdit = true;
        this.dialogVisible = true;
      } else if (cmd === "c") {
        this.$confirm(
          "删除后该规则将不再显示在PDA上，是否确认？",
          this.$t("pop_up.Tips"),
          {
            confirmButtonText: this.$t("pop_up.Determine"),
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.deleteItem(data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消!",
            });
          });
      }
    },
    view() {},
    // 详情
    detailItem(item) {
      this.$axios
        .get("/acb/2.0/freeRule/queryByOperationId", {
          data: {
            operationId: item.operationId,
          },
        })
        .then((res) => {
          this.detailItemList = res.value;
        });
    },
    deleteItem(item) {
      this.$axios
        .get("/acb/2.0/freeRule/deleteByOperationId", {
          data: {
            operationId: item.operationId,
          },
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.searchData();
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/freeRule/query", {
          data: {
            operationId: this.formInline.operationId,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = [];
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  mounted() {
    this.getTenantList();
    this.searchData();
  },
  // activated() {
  //   this.searchData()
  // }
};
</script>
<style lang="stylus" scoped>
.propertyType {
  .breadcrumb {
    height: 35px;
  }

  .content {
    background: #FFFFFF;
    overflow: hidden;

    .pagerWrapper {
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
    }
  }
}
</style>
